import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { graphql } from 'gatsby'

import injectNestedIntlProvider from '../../components/NestedIntlProvider'
import PageLayout from '../../components/layout'
import { urlFor } from '../../components/LinkI18n'
import {
  ServicesFrontendBox,
  ServicesBackednBox,
  Get1BikeBox,
} from '../../components/boxes/boxes'
import BoxesLayout from '../../components/boxes/BoxesLayout'
import Intro from '../../components/Intro'
import Block from '../../components/Block'
import Hr from '../../components/Hr'

import react from '../../images/icons/react.svg'
import django from '../../images/icons/django.svg'
import python from '../../images/icons/python.svg'
import wagtail from '../../images/icons/wagtail.svg'
import js from '../../images/icons/js.svg'

const IndexPage = ({ intl }) => {
  return (
    <FormattedMessage id="pages.home.title">
      {title => (
        <PageLayout
          title={title}
          pageSummary={<Intro />}
          pageClassName="page--home"
        >
          <Block
            title={<FormattedMessage id="pages.home.services" />}
            link={urlFor(intl.locale, 'services/')}
          >
            <BoxesLayout>
              <ServicesFrontendBox />
              <ServicesBackednBox />
            </BoxesLayout>
          </Block>

          <div className="technologies">
            <div className="technologies__images">
              <div className="technologies__img">
                <img src={react} />
              </div>
              <div className="technologies__img">
                <img src={js} />
              </div>
              <div className="technologies__img">
                <img src={python} />
              </div>
              <div className="technologies__img">
                <img src={django} />
              </div>
              <div className="technologies__img">
                <img src={wagtail} />
              </div>
              <div className="technologies__img" />
            </div>
          </div>

          <Block title={<FormattedMessage id="pages.home.portfolio_title" />}>
            <Get1BikeBox />
          </Block>
        </PageLayout>
      )}
    </FormattedMessage>
  )
}

export default injectIntl(injectNestedIntlProvider(IndexPage))

export const query = graphql`
  query($language: String!) {
    messages: allKeyValue(
      filter: {
        file: { relativeDirectory: { eq: "pages" }, name: { eq: $language } }
        key: {
          regex: "/^pages\\.home/"
        }
      }
    ) {
      edges {
        node {
          key
          value
        }
      }
    }
  }
`
