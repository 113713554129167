import React from 'react'
import { injectIntl } from 'react-intl'
import { SoloCell } from './Grid'
import classnames from 'classnames'

export const IntroDefault = ({ title }) => {
  const [start, ...items] = title.split('/')
  const end = items.pop()
  return (
    <div className="page-summary">
      <div className="page-summary__title">
        <div className="im-intro">
          <div className="im-intro__title">
            <div className="im-intro__start">{start}</div>
            <div className="slidingVertical im-intro__sliding">
              {items.map((word, index) => <span key={index}>{word}</span>)}
            </div>
            <div className="im-intro__end">{end}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(({ intl }) => (
  <IntroDefault title={intl.formatMessage({ id: 'pages.home.intro.title' })} />
))
